@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
    background: #fff !important;
    color: #333 !important;
}

body a:hover,
body a:focus,
body a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

body p {
    font-size: .875rem;
}
body p a:hover {
    color: #c86d13;
}

.link:hover {
    color: #c86d13;
}

body p {
    line-height: 1.75;
}

.text-main {
    color: #c86d13 !important;
}
.fs-18{
    font-size: 1.125rem !important;
}

.fs-14{
    font-size: .875rem !important;
}
.fs-12{
    font-size: .75rem !important;
}
.fs-11 {
    font-size: .6875rem !important;
}
.fs-10 {
    font-size: .625rem !important;
}
.fw-500 {
    font-weight: 500 !important;
}
.fw-600 {
    font-weight: 600 !important;
}
hr.hr1 {
    height: 2px !important;
    background: #333;
    opacity: 1;
}

.btn.btn1 {
    background: #c86d13;
    border: 1px solid #c86d13;
    color: #fff;
    box-shadow: none !important;
    outline: none !important;
    border-radius: 50px;
    width: auto;
    min-width: 140px;
    padding: 18px 24px;
    font-size: .875rem;
    font-weight: 500;
    transition: all .4s ease;
}

.btn.btn1:hover {
    background: #000;
    border-color: #000;
    color: #fff;
}
.btn.btn1 i {
    transition: all .5s ease;
}

.btn.btn1:hover i {
    transform: translateX(8px);
}

.padding {
    padding: 60px 8px;
}

.header-top {
    padding: 10px 8px;
}

.logo1 {
    width: 100px;
}

nav.navbar {
    padding: 15px 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}
nav.navbar .navbar-brand {
    padding: 0;
    background: #ffffff24;
    padding: 5px;
    border-radius: 25%;
}
nav.navbar .navbar-toggler {
    padding: 0;
    border: 0;
    box-shadow: none !important;
    outline: none !important;
}
nav.navbar .navbar-nav .nav-link {
    color: #fff;
    font-size: .875rem;
    padding: 10px 15px;
    text-align: center;
}

nav.navbar .navbar-nav .nav-link.active, 
nav.navbar .navbar-nav .nav-link:hover {
    color: #c86d13;
}
nav.navbar .btn.btn1 {
    padding: 12px 24px;
}

/* banner-section */
.banner-section .swiper-slide {
    background-size: cover;
    background-position: center;
    height: calc(100vh - 0px);
    min-height: 500px;
}
.banner-section .swiper-slide .overlay {
    background: rgb(0 0 0 / 80%);
    height: 100%;
    padding: 80px 3px;
    padding-top: 200px;
}

.banner-section .swiper-button-next,.banner-section .swiper-button-prev {
    background-color: hsla(0,0%,100%,.5);
    font-size: 20px;
    height: 47px;
    line-height: 50px;
    width: 45px
}
.banner-section .swiper-pagination-clickable .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #c86d13; 
    border-radius: 50px;
}

.banner-section .swiper-pagination-clickable .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 18px;
    height: 18px;
}

.call-section {
    background-size: cover;
}
.call-section .overlay {
    background: rgb(16 66 132 / 80%);
    min-height: 400px;
}

.banner-about {
    background-size: cover;
}

.banner-about .overlay {
    height: 350px;
    background: rgb(0 0 0 / 80%);
    padding-top: 120px;
}
.breadcrumb .breadcrumb-item {
    color: #c86d13;
}

.breadcrumb .breadcrumb-item.active {
    color: #fff;
}

.breadcrumb .breadcrumb-item::before {
    color: #fff !important;
}

/* .form.form1 */

.form.form1 .form-control {
    height: 50px;
    border-radius: 3px;
    box-shadow: none;
    outline: none;
}

.form.form1 .form-control:focus {
    border-color: #c86d13;
}

.contact-form {
    background: #fff;
    padding: 40px;
    box-shadow: 0 2px 25px rgb(0 0 0 / 15%);
    transition: all .4s ease;
    border-radius: 10px;
}
.iframe{
    height: 450px;
}

.gallery-box {
    background: #fff;
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
    border-radius: 15px;
    overflow: hidden;
    padding: 0;
    transition: all .4s ease;
    position: relative;
    overflow: hidden;
}
.gallery-box img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    transition: all 2s ease;
}

.gallery-box:hover img {
    transform: scale(1.25);
}

.services-box {
    height: 100%;
    padding: 24px;
    background: #fff;
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
    border-radius: 15px;
    transition: all .4s ease;
}
.services-box:hover {
    box-shadow: 0 4px 40px rgb(0 0 0 / 4%);
    transform: translateY(8px);
}

.services-box .icon {
    width: 75px;
    height: 75px;
    line-height: 75px;
    border-radius: 50%;
    text-align: center;
    margin: auto;
    background: #c86d13;
    margin-top: -60px;
    margin-bottom: 24px;
    color: #fff;
}

.program-box {
    background: #fff;
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
    border-radius: 15px;
    overflow: hidden;
    padding: 0;
    transition: all .4s ease;
}
.program-box .program {
    border-radius: 0;
    overflow: hidden;
    position: relative;
}

.program-box .program img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    transition: all 2s ease;
}

.program-box:hover .program img {
    transform: scale(1.25);
}

.program-box .txt {
    padding: 20px;
}
.program-box .program .icon {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: #fff;
    font-size: 3rem;
}
.program-box:hover {
    box-shadow: 0 4px 40px rgb(0 0 0 / 15%);
}

.program-box .btn.btn1 {
    color: #000;
}
.program-box .btn.btn1:hover {
    color: #c86d13;
}






/* scrollbar */
.scrollbar
{
	overflow-y: auto;
}
.scrollbar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #000;
}

/* footer-section */
.footer-section {
    background: rgb(200 109 19 / 3%);
}

.footer-section .logo-foot {
    width: 100px
}

.footer-nav .nav-link {
    color: #333;
    margin-bottom: 8px;
    padding: 0;
    font-size: .875rem;
}

.footer-nav .nav-link:hover {
    color: #c86d13;
}

.footer2 {
    background: #000;
    padding: 20px 0;
}

/* .social */
.social .nav-link {
    padding: 0;
    margin-right: 20px;
    font-size: 1em;
    color: #fff;
}
.social .nav-link:last-child {
    margin-right: 0;
}

.social .nav-link:hover {
    color: #c86d13;
}
