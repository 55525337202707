

@media (min-width: 992px) {
    
}

@media (max-width: 991px) {
    .logo1 {
        width: 80px;
    }
    nav.navbar .navbar-collapse {
        background: #000;
        margin: 0;
        margin-top: 10px;
        border: 0;
        border-radius: 10px;
        padding: 10px 0;
    }
    nav.navbar .btn.btn1 {
        padding: 10px 18px;
        font-size: .75rem;
    }
}

@media (max-width: 767px) {
    .banner-about .overlay {
        height: 280px;
    }
    .iframe{
        height: 400px;
    }

    .contact-grp{
        flex-direction: column;
        align-items: start !important;
    }
    .contact-info-text{
        text-align: left !important;
    }
}

@media (max-width: 575px) {
    .iframe{
        height: 280px;
    }
    .contact-form {
        padding: 24px;
    }
    .gallery-box img {
        height: 240px;
    }
}